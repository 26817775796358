.container {
  margin-bottom: var(--spacing-bottom);
  padding-inline: var(--spacing-sides);

  @media (width >= 1000px) {
    margin-bottom: initial;
  }
}

.inner {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-inline: auto;
  max-width: var(--max-width-revamp-base);

  @media (width >= 1000px) {
    column-gap: var(--spacing-loose);
    flex-direction: row;
  }
}

.content {
  align-items: center;

  @media (width >= 1000px) {
    flex-basis: calc(50% - (var(--spacing-loose) / 2));
    margin-block: var(--spacing-xxxx-loose);
  }
}

.button-wrapper {
  --button-wrapper-flex-direction: row;
  --button-wrapper-justify-content: center;

  @media (width >= 1000px) {
    --button-wrapper-justify-content: start;
  }
}

.teaser {
  max-width: 640px; /* magic number*/
}

.image-wrap {
  display: flex;
  justify-content: center;
  margin-inline: auto;
  width: 100%;

  @media (width >= 1000px) {
    flex-basis: calc(50% - (var(--spacing-loose) / 2));
    justify-content: flex-start;
    margin-inline: initial;
  }
}

.img {
  width: 100%;
  max-width: 448px; /* magic number */

  @media (width >= 1000px) {
    max-width: 454px; /* magic number */
  }
}
